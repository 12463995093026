import React, {FC} from 'react';
import PricingItem from '../pricingItem';
import {hexomaticPackages} from '../pricingPackeges';
import './styles.scss';

const PricingCardDetails: FC<any> = ({name, selectedValue, credits}) => {
  const pricingItem = hexomaticPackages(name.toUpperCase(), credits, name === 'Bronze' ? 3 : name === 'Silver' ? 10 : selectedValue , true);
  return (
    <div className="pricing-card position-relative m-auto">
      <div className="plan-details-card-title">{name}</div>
      {pricingItem.map((item: any) => (
        <PricingItem
          text={item.text}
          ltdPrice={''}
          ltdPriceAjusted={''}
          number={item.number}
          isBold={item.isBold}
          isChecked={item.isChecked}
          unlimited={item.unlimited}
          select={item.select}
          selectedValue={{value: selectedValue, label: credits}}
        />
      ))}
    </div>
  );
};

export default PricingCardDetails;
