import React, {useContext, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {useMutation, useQuery} from '@apollo/react-hooks';
import SimpleLayout from '../../../components/layout_simple';
import Payment from '../../../components/pricing/payment/payment-new';
import {RESEND_VERIFICATION_EMAIL} from '../../../graphql/mutations';
import {UserContext} from '../../../helpers/contexts/user-context';
import {getUserToken, removePackageInfo} from '../../../helpers';
import useHasFocus from '../../../hooks/useHasFocus';
import {GET_HEXOMATIC_USER} from '../../../graphql/queries';
import './styles.scss';

const Checkout = () => {
  const [selected, setSelected] = useState<any>(null);
  const [showPaypalMessage, setShowPaypalMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState<any>(null);
  const focus = useHasFocus();
  const {data: hexomaticUser, loading: userLoading, refetch} = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });

  const [receiveEmail, {loading}] = useMutation(RESEND_VERIFICATION_EMAIL, {
    onCompleted: (data: any) => handleConfirm(data),
  });

  useEffect(() => {
    if (localStorage.getItem('checkout-data')) setSelected(JSON.parse(localStorage.getItem('checkout-data') || ''));
    else if (!success) navigate('/pricing/');
  }, []);

  useEffect(() => {
    if (
      hexomaticUser?.User?.get &&
      !hexomaticUser?.User?.get?.promoData?.hexomatic_ltd &&
      selected.id === 'b78e32d2-82fb-4599-b177-0a97565d3f0f'
    ) {
      navigate('/pricing/');
    }
  }, [hexomaticUser]);

  useEffect(() => {
    if (hexomaticUser?.HexomaticUser && hexomaticUser?.User) {
      let userInfo: any = {};
      if (hexomaticUser.User?.get) userInfo = {...userInfo, ...hexomaticUser.User?.get};
      if (hexomaticUser.HexomaticUser?.getByLegacyId)
        userInfo = {...userInfo, ...hexomaticUser.HexomaticUser?.getByLegacyId};
      if (userInfo.id) setUser(userInfo);
    }
  }, [hexomaticUser]);

  useEffect(() => {
    if (
      user &&
      selected &&
      (!user.id ||
        (user?.pricing_package_interval === 'LTD' && ((selected.interval === 'annual' && !selected.ltd) || selected.interval === 'monthly')) ||
        (user?.pricing_package !== 'FREE' && selected.name === 'Bronze') ||
        (user?.pricing_package !== 'FREE' && user?.pricing_package !== 'BRONZE' && selected.name === 'Silver') ||
        (user?.pricing_package === 'GOLD' &&
          user?.pricing_package_interval === 'MONTHLY' &&
          selected.name === 'GOLD' &&
          selected.interval === 'monthly') || 
        (selected.ltd && user?.pricing_package && user?.pricing_package !== 'FREE' && user?.pricing_package_interval !== 'LTD')) &&
      !success
    ) {
      navigate('/pricing/');
      removePackageInfo();
    } else if (!getUserToken()) {
      navigate('/login/');
    }
  }, [user, selected, focus]);

  const handleConfirm = async (data: any): Promise<void> => {
    if (data.UserOps.resendVerification && !data.UserOps.resendVerification.error) {
      setMessage('success');
    } else if (
      data.UserOps.resendVerification.error &&
      data.UserOps.resendVerification.message === 'Please try few minutes later.'
    ) {
      setMessage(data.UserOps.resendVerification.message);
    } else {
      setMessage('error');
    }
  };
  
  return (
    <>
      {user?.id && !user.verified ? (
        <SimpleLayout isClickable>
          {loading || !user?.id ? (
            <div className="w-100 d-flex">
              <div className="spinner-border spinner-color m-auto" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : message !== 'error' ? (
            <p className="text-center p-2 verify-email-section">
              <div className="text-center">
                <div className="text-center" style={{color: '#516077', fontWeight: 400, fontSize: '18px'}}>
                  <p className="lets-verify">Now let’s verify your email</p>
                  Check <span className="email-blue">{user?.email}</span> to verify your account and get started.
                </div>
              </div>
              <p className="mt-2" style={{lineHeight: '18px', color: '#516077', fontWeight: 400}}>
                <span className="click-here" onClick={() => receiveEmail()}>
                  Click here
                </span>{' '}
                to resend the confirmation email.
              </p>

              {message && (
                <p className={`text-${message === 'success' ? 'success' : 'danger'} center mt-3`}>
                  {message === 'success' ? 'Verification email successfully sent!' : message}
                </p>
              )}
            </p>
          ) : (
            <>{message === 'error' && <p className="text-danger text-center">Verification Failed!</p>}</>
          )}
        </SimpleLayout>
      ) : (
        <SimpleLayout noIllustration>
          <div className="checkout-page">
            {loading || userLoading || !user?.id ? (
              <div className="w-100 d-flex">
                <div className="spinner-border spinner-color m-auto" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <>
                {selected && (
                  <Payment
                    id={selected.id}
                    paypal_id={selected.paypal_id}
                    count={selected.selectedValue.value}
                    credits={selected.selectedValue.label}
                    showPaypalMessage={showPaypalMessage}
                    setShowPaypalMessage={setShowPaypalMessage}
                    price={selected.price}
                    ltdPriceAjusted={selected.ltdPriceAjusted}
                    isTrial={selected.isTrial}
                    name={selected.name}
                    setSuccess={setSuccess}
                    success={success}
                    annual={selected.interval === 'annual'}
                    user={user}
                    ltd={selected.ltd}
                  />
                )}
              </>
            )}
          </div>
        </SimpleLayout>
      )}
    </>
  );
};

export default Checkout;
